<template>
  <div class="w-100 transition">
    <GenericReport
      :title="title"
      :route="route"
      :fields="tableFields"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      tipoRelatorio="relatorioMateriais"
    />
  </div>
</template>

<script>
import moment from 'moment';
import GenericReport from '@/components/Table/GenericReport';
import GenericApi from '@/services/genericRequest';

export default {
  name: 'relatorioRecebimentoMateriaisComAviso',
  components: {
    GenericReport,
  },

  data() {
    return {
      route: 'report/recebimentoMateriaisComAviso',
      title: '',
      tableFields: [],
      topFilters: {
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
      },
      sideFilters: {
        0: {},
      },
    };
  },

  mounted() {
    this.getConfiguracoesRelatorio();
  },

  methods: {
    async getConfiguracoesRelatorio() {
      const configuracoesRelatorio = await GenericApi.get({},
        'configuracaoRelatorio/CONSULTA_MATERIAIS_RECEBIDOS_COM_AVISO');
      this.tableFields = configuracoesRelatorio.fields[0].fieldsTabela;
      this.title = configuracoesRelatorio.descricao;
      this.sideFilters = configuracoesRelatorio.options[0].sideFilters;
      this.topFilters = configuracoesRelatorio.options[0].topFilters;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
